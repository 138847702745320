main {
  .posts {
    margin-bottom: 30px;
    .post {
      padding: 15px;
      display: flex;
      border: 1px solid $kek;
      border-radius: 10px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      img {
        width: 200px;
        height: auto;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
      }
    }
  }
}
