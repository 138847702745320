aside {
  .sidebar {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid $kek;
    ul {
      .items {
        margin-bottom: 15px;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
