footer {
  margin-top: 30px;
  height: 60px;
  background-color: $kek;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #fff;
    text-align: center;
  }
}
