@import "./styles/variables";
@import "~bootstrap/scss/bootstrap";
@import "./styles/navbar";
@import "./styles/main";
@import "./styles/aside";
@import "./styles/form-group";
@import "./styles/footer";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
